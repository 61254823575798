























import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/designer/header/Header.vue";
import Menu from "@/components/designer/header/Menu.vue";
import Configurator from "@/components/designer/Configurator.vue";

@Component({
  components: {
    Header,
    Menu,
    Configurator
  }
})
export default class Designer extends Vue {
  protected isViewerReady = false;

  protected ready(): void {
    this.isViewerReady = true;
  }

  protected editUmbrella(): void {
    this.$router.push("/?design=" + this.$route.query.design + "&edit=true");
  }

  protected createNewUmbrella(): void {
    this.$router.push("/collectionmenu");
  }
}
